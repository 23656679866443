import { NavLink, Outlet, useMatches, type UIMatch } from "@remix-run/react";

const isActive = (matches: UIMatch<unknown, unknown>[], pathname: string) =>
  Boolean(matches.at(-1)?.pathname === pathname);

const menuItems = [
  ["home", "/"],
  ["me", "/me"],
  ["blog", "/blog"],
] as Array<[label: string, path: string]>;

export default function PublicLayout() {
  const matches = useMatches();
  const headerBorder = `+${menuItems
    .map((i) => i[0].length + 2)
    .map((i) => "-".repeat(i))
    .join("+")}+`;
  return (
    <>
      <header className="flex justify-between">
        <pre><br /><NavLink className="text-main-300" to="/">muningis.lt</NavLink></pre>
        <pre>
          {headerBorder}{"\n"}
          |{menuItems.flatMap((v,i) => i !== menuItems.length - 1 ? [v, "|"] : [v]).map((i) => typeof i === "string" ? i : <NavLink className={({isActive}) => isActive ? "text-main-300" : ""} key={i[0]} to={i[1]}>{` ${i[0]} `}</NavLink>)}|{"\n"}
          {headerBorder}
          {"\n"}{"\n"}{"\n"}
        </pre>
      </header>
      <main className="grow mb-16 font-syne">
        <Outlet />
      </main>
      <footer className="py-4">
        <pre>© 2024 Rokas Muningis</pre>
      </footer>
    </>
  );
}
